import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import moment from "moment";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";

// Material Icons
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";

const MentionCardBased = (props) => {
  const { data, type } = props;

  const HeaderTwitter = () => {
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3}>
            <Avatar
              alt={data.username}
              src={data.image_url}
              sx={{ width: 55, height: 55 }}
            />
          </Grid>
          <Grid item xs={9} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <Typography variant="caption" color="text.secondary">
                  Retweet:
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color="text.secondary"
                >
                  {!data.retweet_count || null ? "-" : data.retweet_count}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mr: "5px" }}
                >
                  Reply:
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color="text.secondary"
                >
                  {!data.reply_count || null ? "-" : data.reply_count}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mr: "5px" }}
                >
                  Sentiment
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color={filteredSentiment(data.sentiment)}
                >
                  {!data.sentiment || null ? "-" : data.sentiment}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  const HeaderInstagram = () => {
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3}>
            <Avatar
              alt={data.username}
              src={data.image_url}
              sx={{ width: 55, height: 55 }}
            />
          </Grid>
          <Grid item xs={9} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <Typography variant="caption" color="text.secondary">
                  Likes
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color="text.secondary"
                >
                  {!data.like_count || null ? "-" : data.like_count}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="caption" color="text.secondary">
                  Comments
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color="text.secondary"
                >
                  {!data.comment_count || null ? "-" : data.comment_count}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography variant="caption" color="text.secondary">
                  Sentiment
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  fontWeight="600"
                  color={filteredSentiment(data.sentiment)}
                >
                  {!data.sentiment || null ? "-" : data.sentiment}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  const HeaderGoogle = () => {
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" fontWeight="600" sx={{ mb: "15px" }}>
              {!data.title || null ? "-" : data.title}
            </Typography>
            <Chip
              label={data.sentiment}
              sx={{
                backgroundColor: filteredSentiment(data.sentiment),
                color: "#ffffff",
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const filteredSentiment = (sentiment) => {
    switch (sentiment) {
      case "Positif":
        return "#17b978";
      case "Negatif":
        return "#cb3b3b";
      default:
        return "#c5e3f6";
    }
  };
  const filterReadMore = (socialMediaType) => {
    switch (socialMediaType) {
      case "Twitter":
        return data.tweet_url;
      case "Instagram":
        return data.post_url;
      case "Google":
        return data.link;
      default:
        return "#";
    }
  };
  const filterHeader = (socialMediaType) => {
    switch (socialMediaType) {
      case "Twitter":
        return <HeaderTwitter />;
      case "Instagram":
        return <HeaderInstagram />;
      case "Google":
        return <HeaderGoogle />;
      default:
        return null;
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {filterHeader(type)}
          </Grid>
          {type === "Google" ? null : (
            <Grid item xs={12} md={12} sx={{ mt: 0.5 }}>
              <Typography variant="body2" fontWeight="600">
                {!data.username || null ? "-" : data.username}
              </Typography>
            </Grid>
          )}
          {type === "Google" ? null : (
            <Grid item xs={12} md={12}>
              <div style={{ display: "flex" }}>
                <div style={{ alignSelf: "center" }}>
                  <EventOutlinedIcon
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "20px",
                      mr: "5px",
                    }}
                  />
                </div>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mr: "10px", alignSelf: "center" }}
                >
                  {!data.created_at || null
                    ? "-"
                    : moment(data.created_at).format("LLL")}
                </Typography>
                <div style={{ alignSelf: "center" }}>
                  <LocationOnOutlinedIcon
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "20px",
                      mr: "5px",
                    }}
                  />
                </div>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ alignSelf: "center" }}
                >
                  {!data.location || null ? "-" : data.location}
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            {type === "Google" ? (
              <div sx={{ mt: 1 }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                  {!data.desc || null ? "-" : data.desc}
                </Typography>
                <hr style={{ border: "0.3px solid #f2f2f2" }} />
                <Button
                  variant="outlined"
                  component={Link}
                  target={"_blank"}
                  to={filterReadMore(type)}
                  sx={{ mt: 1.5 }}
                  fullWidth
                >
                  Read More
                </Button>
              </div>
            ) : (
              <div sx={{ mt: 1 }}>
                <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                  {!data.full_text || null ? "-" : data.full_text}
                </Typography>
                <hr style={{ border: "0.3px solid #f2f2f2" }} />
                <Button
                  variant="outlined"
                  component={Link}
                  target={"_blank"}
                  to={filterReadMore(type)}
                  sx={{ mt: 1.5 }}
                  fullWidth
                >
                  Read More
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default function MentionCards({ ...props }) {
  const { data, type } = props;

  return (
    <Grid container spacing={2}>
      {data.map((data) => (
        <Grid item key={data.id} xs={12} md={4}>
          <MentionCardBased data={data} type={type} />
        </Grid>
      ))}
    </Grid>
  );
}
