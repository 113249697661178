import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Components
import SocialMediaMention from "../components/Card/SocialMediaMention";
import DigitalAverageCount from "../components/Card/Average";
import DigitalAverageMentionCount from "../components/Card/AverageMention";
import DigitalSentiment from "../components/Chart/Sentiment";
import RecentPosts from "../components/Card/RecentPosts";
import TopAverageChart from "../components/Chart/TopAverageBar";
import EmptyData from "../components/EmptyData";

// Material Icons
import InstagramIcon from "@mui/icons-material/Instagram";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import BlurOnOutlinedIcon from "@mui/icons-material/BlurOnOutlined";

export default function InstagramPage({ ...props }) {
  const { data } = props;

  const sortTopLike = (array) => {
    const filteredLikeData = array.sort((a, b) => {
      return b.like_count - a.like_count;
    });

    return filteredLikeData.slice(0, 6);
  };

  const sortTopComment = (array) => {
    const filteredCommentData = array.sort((a, b) => {
      return b.comment_count - a.comment_count;
    });

    return filteredCommentData.slice(0, 6);
  };

  const sortSentimentLength = (array, sentiment) => {
    const filteredData = array.filter((x) => x.sentiment === sentiment);
    return filteredData.length;
  };

  return (
    <Fragment>
      {data?.data?.length > 0 ? (
        <Grid container spacing={4} sx={{ pb: 8 }}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body1" fontWeight="600" sx={{ mb: 1 }}>
                      Digital Mention
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SocialMediaMention icon={<InstagramIcon />} data={data} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DigitalAverageCount
                      average={data.total_like}
                      isAverage={false}
                      title="Total Likes"
                      icon={
                        <ThumbUpOutlinedIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DigitalAverageCount
                      average={data.total_comment}
                      isAverage={false}
                      title="Total Comments"
                      icon={
                        <ChatOutlinedIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" fontWeight="600" sx={{ mb: 1 }}>
                  Sentiment Percentage Analysis
                </Typography>
                <DigitalSentiment sentiment={data} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body1" fontWeight="600" sx={{ mb: 1 }}>
                      Sentiment Analysis
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DigitalAverageCount
                      average={sortSentimentLength(data.data, "Netral")}
                      isAverage={true}
                      title="Total of Netral Sentiment"
                      icon={
                        <BlurOnOutlinedIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DigitalAverageCount
                      average={sortSentimentLength(data.data, "Negatif")}
                      isAverage={true}
                      title="Total of Negatif Sentiment"
                      icon={
                        <ThumbDownOutlinedIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DigitalAverageCount
                      average={sortSentimentLength(data.data, "Positif")}
                      isAverage={true}
                      title="Total of Positive Sentiment"
                      icon={
                        <ThumbUpOutlinedIcon
                          sx={{
                            width: 25,
                            height: 25,
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body1" fontWeight="600" sx={{ mb: 1 }}>
                      Most Mentioned Account
                    </Typography>
                  </Grid>
                  {Object.keys(data.most_mention_account).map((key) => (
                    <Grid item xs={12} md={4}>
                      <DigitalAverageMentionCount
                        count={data.most_mention_account[key]}
                        title={key}
                        link={"https://instagram.com/" + key}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" fontWeight="600">
                  Average Likes
                </Typography>
                <TopAverageChart
                  data={data.data}
                  type="retweet_count"
                  socialMediaType="Instagram"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" fontWeight="600">
                  Average Comments
                </Typography>
                <TopAverageChart
                  data={data.data}
                  type="reply_count"
                  socialMediaType="Instagram"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="600" sx={{ mb: 3 }}>
                  Top Like Post
                </Typography>
                <RecentPosts data={sortTopLike(data.data)} type={"Instagram"} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="600" sx={{ mb: 3 }}>
                  Top Comment Post
                </Typography>
                <RecentPosts
                  data={sortTopComment(data.data)}
                  type={"Instagram"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <EmptyData />
      )}
    </Fragment>
  );
}
