import React, { Fragment } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Components
import SocialMediaMention from "../../components/Card/SocialMediaMention";
import DigitalSentiment from "../../components/Chart/Sentiment";
import DigitalAverageCount from "../../components/Card/Average";
import DigitalAverageMentionCount from "../../components/Card/AverageMention";
import TopAverageChart from "../../components/Chart/TopAverageBar";

// Material Icons
import InstagramIcon from "@mui/icons-material/Instagram";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import BlurOnOutlinedIcon from "@mui/icons-material/BlurOnOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export default function InstagramSummary({ ...props }) {
  const { data, positiveSentiment, negativeSentiment, netralSentiment } = props;

  return (
    <Fragment>
      <Accordion
        sx={{
          borderRadius: "10px",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
          <Typography variant="body1" fontWeight="600">
            Platform: Instagram
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 4, pt: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" fontWeight="600" sx={{ mb: 2 }}>
                Sentiment Analysis
              </Typography>
              <DigitalSentiment sentiment={data} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" fontWeight="600" sx={{ mb: 2 }}>
                Digital Mention
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <SocialMediaMention icon={<InstagramIcon />} data={data} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DigitalAverageCount
                    average={data.total_like}
                    isAverage={false}
                    title="Total Likes"
                    icon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DigitalAverageCount
                    average={data.total_comment}
                    isAverage={false}
                    title="Total Comments"
                    icon={
                      <ChatOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" fontWeight="600" sx={{ mb: 1 }}>
                    Sentiment Analysis
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DigitalAverageCount
                    average={netralSentiment}
                    isAverage={true}
                    title="Total of Netral Sentiment"
                    icon={
                      <BlurOnOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DigitalAverageCount
                    average={negativeSentiment}
                    isAverage={true}
                    title="Total of Negatif Sentiment"
                    icon={
                      <ThumbDownOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DigitalAverageCount
                    average={positiveSentiment}
                    isAverage={true}
                    title="Total of Positive Sentiment"
                    icon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" fontWeight="600" sx={{ mb: 1 }}>
                    Most Mentioned Account
                  </Typography>
                </Grid>
                {Object.keys(data.most_mention_account).map((key) => (
                  <Grid item xs={12} md={4}>
                    <DigitalAverageMentionCount
                      count={data.most_mention_account[key]}
                      title={key}
                      link={"https://instagram.com/" + key}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" fontWeight="600" sx={{ mb: 2 }}>
                Average Like Count
              </Typography>
              <TopAverageChart
                data={data.data}
                type="retweet_count"
                socialMediaType="Instagram"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" fontWeight="600" sx={{ mb: 2 }}>
                Average Comment Count
              </Typography>
              <TopAverageChart
                data={data.data}
                type="reply_count"
                socialMediaType="Instagram"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}
