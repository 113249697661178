import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

export default function SentimentCards({ ...props }) {
  const { sentiment } = props;

  const getSentimentLength = (array, sentiment) => {
    const filteredData = array.filter((x) => x.sentiment === sentiment);
    return filteredData.length;
  };

  const getArcLabel = (array, sentiment) => {
    const percent = getSentimentLength(array, sentiment) / array.length;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const data = [
    {
      id: 0,
      value: !sentiment.data
        ? getSentimentLength(sentiment, "Netral")
        : getSentimentLength(sentiment.data, "Netral"),
      percentage: !sentiment.data
        ? getArcLabel(sentiment, "Netral")
        : getArcLabel(sentiment.data, "Netral"),
      label: "Netral",
      color: "#c5e3f6",
    },
    {
      id: 1,
      value: !sentiment.data
        ? getSentimentLength(sentiment, "Positif")
        : getSentimentLength(sentiment.data, "Positif"),
      percentage: !sentiment.data
        ? getArcLabel(sentiment, "Positif")
        : getArcLabel(sentiment.data, "Positif"),
      label: "Positive",
      color: "#17b978",
    },
    {
      id: 2,
      value: !sentiment.data
        ? getSentimentLength(sentiment, "Negatif")
        : getSentimentLength(sentiment.data, "Negatif"),
      percentage: !sentiment.data
        ? getArcLabel(sentiment, "Negatif")
        : getArcLabel(sentiment.data, "Negatif"),
      label: "Negative",
      color: "#cb3b3b",
    },
  ];

  return (
    <Paper
      sx={{
        p: 1,
        borderRadius: "10px",
        boxShadow: "unset",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.percentage}`,
                // arcLabelMinAngle: 25,
                data,
                valueFormatter: (v, { dataIndex }) => {
                  return `${v.percentage}`;
                },
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                paddingAngle: 5,
                cornerRadius: 5,
                innerRadius: 30,
              },
            ]}
            height={350}
            margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "bottom", horizontal: "middle" },
                padding: 0,
                itemMarkWidth: 20,
                markGap: 9,
                itemGap: 10,
                labelStyle: {
                  fontSize: 12,
                  fill: "#1a1a1a",
                },
              },
            }}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
                fontWeight: "bold",
                letterSpacing: "-1px",
              },
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
