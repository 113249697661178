import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import moment from "moment";

const MentionCardBased = (props) => {
  const { data, icon } = props;

  const filterDate = () => {
    let newArray = data.map((x) => ({
      ...x,
      filtered_date: moment(x.created_at).format("YYYY-MM-DD"),
    }));

    return newArray.sort((a, b) => {
      let dateA = new Date(moment(a.filtered_date).format("YYYY-MM-DD"));
      let dateB = new Date(moment(b.filtered_date).format("YYYY-MM-DD"));

      // Extract year and month
      const yearA = dateA.getFullYear();
      const yearB = dateB.getFullYear();
      const monthA = dateA.getMonth();
      const monthB = dateB.getMonth();

      // Compare years first
      if (yearA !== yearB) {
        return yearA - yearB;
      }

      // If years are the same, compare months
      return monthA - monthB;
    });
  };

  const displayDateRange = (data) => {
    const firstData = data[0];
    const lastData = data[data.length - 1];
    const output = {
      from: moment(firstData.created_at).format("ll"),
      to: moment(lastData.created_at).format("ll"),
    };

    return output;
  };

  return (
    <Paper
      sx={{
        p: 3,
        pb: 2,
        borderRadius: "10px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Avatar
            sx={{ backgroundColor: "#1a1a1a", mb: 2, width: 50, height: 50 }}
          >
            {icon}
          </Avatar>
          <Typography
            variant="h2"
            fontWeight="600"
            sx={{ letterSpacing: "-1px" }}
          >
            {data.length}
          </Typography>
          <div style={{ float: "left" }}>
            <Typography variant="body2" color="#888888" gutterBottom>
              Total Mention
            </Typography>
          </div>
          <div style={{ float: "right" }}>
            {!data[0].created_at || null ? (
              "-"
            ) : (
              <Typography variant="caption" color="#888888">
                {displayDateRange(filterDate()).from} -{" "}
                {displayDateRange(filterDate()).to}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function SummaryMention({ ...props }) {
  const { data, icon } = props;

  return <MentionCardBased data={data.data} icon={icon} />;
}
