import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import Button from "@mui/material/Button";
import { useTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Components
import AppBar from "../components/AppBar";

// Pages
import TwitterPage from "./Twitter";
import InstagramPage from "./Instagram";
import GooglePage from "./Google";
import SummaryPage from "./Summary";

// Assets
import analyticsImg from "../assets/analytics.svg";

// Material Icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Dashboard() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up("sm"));

  const getInitialData = (type) => {
    const temp = localStorage.getItem(type);
    const savedFile = JSON.parse(temp);
    return savedFile || [];
  };

  const [value, setValue] = useState("1");
  const [fileUploaded, setFileUploaded] = useState(
    getInitialData("fileUploaded")
  );
  const [fileName, setFileName] = useState(getInitialData("fileName"));
  const [userLogin, setUserLogin] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const tempFileUploaded = JSON.stringify(fileUploaded);
    const tempFileName = JSON.stringify(fileName);
    localStorage.setItem("fileUploaded", tempFileUploaded);
    localStorage.setItem("fileName", tempFileName);
    if (user) {
      setUserLogin(user);
    }
  }, [fileUploaded, fileName]);

  const handleChangeJsonFile = (e) => {
    const fileReader = new FileReader();
    const { files } = e.target;

    fileReader.readAsText(files[0], "UTF-8");
    fileReader.onload = (e) => {
      const content = e.target.result;
      setFileName(files[0].name);
      setFileUploaded(JSON.parse(content));
    };
  };

  const handleChangeClearJsonFile = () => {
    // localStorage.clear();
    localStorage.removeItem("fileUploaded");
    localStorage.removeItem("fileName");
    setFileUploaded([]);
    setFileName("");
    setUserLogin("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#F8F8F8",
          height: "230px",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          borderRadius: "unset",
          boxShadow: "unset",
        }}
      />
      <Container maxWidth="lg" sx={{ p: 1 }}>
        <div style={{ position: "relative" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ pb: "15px" }}>
              <AppBar />
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper
                sx={{
                  p: 3,
                  boxShadow: "unset",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="h3" fontWeight="600" gutterBottom>
                  Hi 👋🏻, {userLogin.fullname}
                </Typography>
                <Typography variant="body2">
                  {moment().format("LLL")}
                </Typography>
                <div style={{ marginTop: "35px" }}>
                  {fileUploaded.length === 0 ? (
                    <Button
                      component="label"
                      onChange={handleChangeJsonFile}
                      fullWidth
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload file
                      <VisuallyHiddenInput
                        type="file"
                        accept="application/json"
                      />
                    </Button>
                  ) : (
                    <div>
                      <TextField
                        fullWidth
                        disabled
                        value={fileName === "" ? "-" : fileName}
                        label="Uploaded CSV"
                        variant="standard"
                        sx={{ mb: 4 }}
                      />
                      <Button
                        component="label"
                        onClick={handleChangeClearJsonFile}
                        fullWidth
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={<CancelIcon />}
                      >
                        Clear Data
                      </Button>
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
            {fileUploaded.length === 0 ? (
              <Box sx={{ width: "100%", textAlign: "center", mt: 5, mb: 15 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <img
                      src={analyticsImg}
                      alt="Polysphere Analytics"
                      width={isMobileView ? "350px" : "250px"}
                      style={{ marginBottom: "10px" }}
                    />
                    <Typography variant="body1" fontWeight="600" gutterBottom>
                      Visualizing Interactive and real-time data for Monitoring
                      and Analysis🙌🏻
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Upload the Generated CSV in order to Visualize the Data
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Grid item xs={12} md={12}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="Twitter / X"
                        value="1"
                        sx={{ letterSpacing: "-0.5px" }}
                      />
                      <Tab
                        label="Instagram"
                        value="2"
                        sx={{ letterSpacing: "-0.5px" }}
                      />
                      <Tab
                        label="Google"
                        value="3"
                        sx={{ letterSpacing: "-0.5px" }}
                      />
                      <Tab
                        label="Summary"
                        value="4"
                        sx={{ letterSpacing: "-0.5px" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel sx={{ pl: 0, pr: 0 }} value="1">
                    <TwitterPage
                      data={
                        fileUploaded?.twitter?.data?.length === 0
                          ? []
                          : fileUploaded.twitter
                      }
                    />
                  </TabPanel>
                  <TabPanel sx={{ pl: 0, pr: 0 }} value="2">
                    <InstagramPage
                      data={
                        fileUploaded?.instagram?.data?.length === 0
                          ? []
                          : fileUploaded.instagram
                      }
                    />
                  </TabPanel>
                  <TabPanel sx={{ pl: 0, pr: 0 }} value="3">
                    <GooglePage
                      data={
                        fileUploaded?.google?.data?.length === 0
                          ? []
                          : fileUploaded.google
                      }
                    />
                  </TabPanel>
                  <TabPanel sx={{ pl: 0, pr: 0 }} value="4">
                    <SummaryPage
                      data={fileUploaded.length === 0 ? [] : fileUploaded}
                    />
                  </TabPanel>
                </TabContext>
              </Grid>
            )}
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
}
