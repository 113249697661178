import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

const AverageMentionCardBased = (props) => {
  const { count, title, link } = props;

  return (
    <Paper
      sx={{
        p: 3,
        borderRadius: "10px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "80%" }}>
              <Typography variant="body2" fontWeight="600">
                {title}
              </Typography>
              <Typography
                component={Link}
                to={link}
                target="_blank"
                rel="noopener noreferrer"
                variant="caption"
                fontWeight="500"
                color="primary"
                sx={{ textDecoration: "none" }}
              >
                Visit Profile
              </Typography>
            </div>
            <div
              style={{
                width: "20%",
                textAlign: "right",
                alignContent: "center",
              }}
            >
              <NumericFormat
                value={count}
                thousandSeparator=","
                displayType="text"
                style={{
                  fontWeight: "600",
                  letterSpacing: "-1px",
                  fontSize: "24px",
                  lineHeight: "36px",
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function SummaryAverageMetion({ ...props }) {
  const { count, title, link } = props;

  return <AverageMentionCardBased count={count} title={title} link={link} />;
}
