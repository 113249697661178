import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";

// Assets
import polysphereLogo from "../assets/polysphere-logo-main.svg";

// Auth
import { useAuth } from "../hooks/useAuth";

// Data
import userJsonData from "../data/user.json";

// Material Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Components
import Snackbar from "../components/Snackbar";

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(true);
  const [body, setBody] = useState({ email: "", password: "" });
  const [isLogin, setIsLogin] = useState(false);
  const [openSnackbar, setIsOpenSnackbar] = useState(false);
  const { login } = useAuth();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = (event) => {
    event.preventDefault();
    userJsonData.filter((x) => {
      if (x.email === body.email && x.password === body.password) {
        setIsLogin(true);
        setIsOpenSnackbar(true);
        return login({ email: body.email, fullname: x.fullname });
      } else {
        setIsLogin(false);
        setIsOpenSnackbar(true);
        return null;
      }
    });
  };

  const handleChangeEmail = (e) => {
    setBody({ ...body, email: e.target.value });
  };
  const handleChangePassword = (e) => {
    setBody({ ...body, password: e.target.value });
  };

  const handleCloseSnackbar = (e) => {
    setIsOpenSnackbar(false);
  };

  return (
    <Container maxWidth="xs" sx={{ boxShadow: "unset" }}>
      <Snackbar
        isOpen={openSnackbar}
        status={isLogin}
        isClose={handleCloseSnackbar}
      />
      <Paper
        sx={{
          pr: 3,
          pl: 3,
          pb: 2,
          borderRadius: "10px",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <img
                src={polysphereLogo}
                alt="ISESS Logo"
                width="180px"
                style={{ paddingRight: "20px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="600" gutterBottom>
                Sign In
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Policy Analytics Tools
              </Typography>
            </Grid>
          </Grid>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="600"
                  gutterBottom
                >
                  Email Address
                </Typography>
                <TextField
                  required
                  fullWidth
                  type="email"
                  onChange={handleChangeEmail}
                  autoComplete="email"
                  placeholder="Input Your Email Address"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="600"
                  gutterBottom
                >
                  Password
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showPassword ? "password" : "text"}
                  placeholder="Input Your Password"
                  onChange={handleChangePassword}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              onClick={handleSubmit}
              variant="contained"
              sx={{ mt: 5, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
