import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Components
import Footer from "./components/Footer";

// Auth
import { ProtectedRoute } from "./components/Auth/ProtectedRoute";
import { ProtectedLogin } from "./components/Auth/ProtectedLogin";
import { AuthProvider } from "./hooks/useAuth";

// Pages
import Dashboard from "./pages/Index";
import Login from "./pages/Login";

const theme = createTheme({
  palette: {
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: "#014576",
    },
    secondary: {
      main: "#5AEACF",
    },
  },
  typography: {
    fontFamily: `"Inter", sans-serif`,
    h1: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "48px",
      letterSpacing: "-0.5px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "36px",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "-0.5px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "28px",
      letterSpacing: "-0.5px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "-0.5px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "-0.5px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedLogin>
                <Login />
              </ProtectedLogin>
            }
            exact
          ></Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
        <Footer />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
