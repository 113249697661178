import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Components
import DigitalSentiment from "../components/Chart/Sentiment";
import DigitalAverageCount from "../components/Card/Average";
import EmptyData from "../components/EmptyData";

// Accordions
import TwitterSummaryPage from "./summary/Twitter";
import InstagramSummaryPage from "./summary/Instagram";
import GoogleSummaryPage from "./summary/Google";

// Material Icons
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import BlurOnOutlinedIcon from "@mui/icons-material/BlurOnOutlined";

export default function SummaryPage({ ...props }) {
  const { data } = props;

  const twitterDataArray = data.twitter;
  const instagramDataArray = data.instagram;
  const googleDataArray = data.google;

  const combinedArray = [
    ...twitterDataArray.data,
    ...instagramDataArray.data,
    ...googleDataArray.data,
  ];

  const sortSentimentLength = (array, sentiment) => {
    const filteredData = array.filter((x) => x.sentiment === sentiment);
    return filteredData.length;
  };

  const countSummarySentiment = (sentiment) => {
    const twitterData = sortSentimentLength(twitterDataArray.data, sentiment);
    const instagramData = sortSentimentLength(
      instagramDataArray.data,
      sentiment
    );
    const googleData = sortSentimentLength(googleDataArray.data, sentiment);
    const countData = twitterData + instagramData + googleData;

    return countData;
  };

  const rearrangedArray = combinedArray.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ pb: 8 }}>
        {/* Summary */}
        <Grid item xs={12} md={12}>
          <Typography variant="body1" fontWeight="600" gutterBottom>
            Social Media Reach
          </Typography>
          <Typography
            variant="body2"
            fontStyle="italic"
            color="#7e7e7e"
            sx={{ mb: 1 }}
          >
            Total amount of sentiment from all available Social Media.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <DigitalSentiment sentiment={rearrangedArray} />
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <DigitalAverageCount
                    average={countSummarySentiment("Netral")}
                    isAverage={true}
                    title="Total of Netral Sentiment"
                    icon={
                      <BlurOnOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DigitalAverageCount
                    average={countSummarySentiment("Negatif")}
                    isAverage={true}
                    title="Total of Negatif Sentiment"
                    icon={
                      <ThumbDownOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DigitalAverageCount
                    average={countSummarySentiment("Positif")}
                    isAverage={true}
                    title="Total of Positive Sentiment"
                    icon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          width: 25,
                          height: 25,
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 2, mb: 1 }}>
          <hr style={{ border: "0.5px solid #eeeeee" }} />
        </Grid>
        {/* Twitter */}
        {data?.twitter?.data?.length === 0 ? (
          <Grid item xs={12} md={12}>
            <EmptyData />
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <TwitterSummaryPage
              data={twitterDataArray}
              positiveSentiment={sortSentimentLength(
                twitterDataArray.data,
                "Positif"
              )}
              negativeSentiment={sortSentimentLength(
                twitterDataArray.data,
                "Negatif"
              )}
              netralSentiment={sortSentimentLength(
                twitterDataArray.data,
                "Netral"
              )}
            />
          </Grid>
        )}
        {/* Instagram */}
        {data?.instagram?.data?.length === 0 ? (
          <Grid item xs={12} md={12}>
            <EmptyData />
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <InstagramSummaryPage
              data={instagramDataArray}
              positiveSentiment={sortSentimentLength(
                instagramDataArray.data,
                "Positif"
              )}
              negativeSentiment={sortSentimentLength(
                instagramDataArray.data,
                "Negatif"
              )}
              netralSentiment={sortSentimentLength(
                instagramDataArray.data,
                "Netral"
              )}
            />
          </Grid>
        )}
        {/* Google */}
        {data?.google?.data?.length === 0 ? (
          <Grid item xs={12} md={12}>
            <EmptyData />
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <GoogleSummaryPage
              data={googleDataArray}
              positiveSentiment={sortSentimentLength(
                googleDataArray.data,
                "Positif"
              )}
              negativeSentiment={sortSentimentLength(
                googleDataArray.data,
                "Negatif"
              )}
              netralSentiment={sortSentimentLength(
                googleDataArray.data,
                "Netral"
              )}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
