import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

// Assets
import analyticsImg from "../assets/analytics.svg";

export default function EmptyData() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={{ width: "100%", textAlign: "center", mt: 2, mb: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <img
            src={analyticsImg}
            alt="Polysphere Analytics"
            width={isMobileView ? "350px" : "250px"}
            style={{ marginBottom: "10px" }}
          />
          <Typography variant="body1" fontWeight="600" gutterBottom>
            No Data to Visualize😓
          </Typography>
          <Typography variant="body2" color="text.secondary">
            We don't find any match data yet, you may try again!
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
