import * as React from "react";
import Stack from "@mui/material/Stack";
import { BarChart } from "@mui/x-charts/BarChart";
import moment from "moment";

export default function TopAverage({ ...props }) {
  const { data, type, socialMediaType } = props;

  const sortByYearAndMonth = (array, socialMediaType) => {
    let newArray = array.map((x) => ({
      id: x.id,
      retweet_count:
        socialMediaType === "Twitter" ? x.retweet_count : x.like_count,
      reply_count:
        socialMediaType === "Twitter" ? x.reply_count : x.comment_count,
      filtered_date: moment(x.created_at).format("YYYY-MM-DD"),
    }));

    return newArray.sort((a, b) => {
      let dateA = new Date(moment(a.filtered_date).format("YYYY-MM-DD"));
      let dateB = new Date(moment(b.filtered_date).format("YYYY-MM-DD"));

      // Extract year and month
      const yearA = dateA.getFullYear();
      const yearB = dateB.getFullYear();
      const monthA = dateA.getMonth();
      const monthB = dateB.getMonth();

      // Compare years first
      if (yearA !== yearB) {
        return yearA - yearB;
      }

      // If years are the same, compare months
      return monthA - monthB;
    });
  };

  const groupByYearAndMonth = (array, type) => {
    const groupedData = {};

    array.forEach((item) => {
      const date = new Date(item.filtered_date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // months are 0-based, so we add 1

      const key = `${year}-${month.toString().padStart(2, "0")}`;
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item[type]);
    });

    return groupedData;
  };

  const calculateAverages = (array) => {
    const averages = [];

    for (const [key, values] of Object.entries(array)) {
      const total = values.reduce((sum, value) => sum + value, 0);
      const average = total / values.length;
      averages.push({ monthYear: key, averageValue: average });
    }

    return averages;
  };

  const sortedData = sortByYearAndMonth(data, socialMediaType);
  const groupedData = groupByYearAndMonth(sortedData, type);
  const averages = calculateAverages(groupedData);

  const seriesData = averages.map((x) => {
    return parseFloat(x.averageValue.toFixed(2));
  });
  const seriesLabel = averages.map((x) => {
    return new Date(x.monthYear).toISOString().slice(0, 10);
  });

  const filteredColor = (type) => {
    switch (type) {
      case "retweet_count":
        return ["#014576"];
      case "reply_count":
        return ["#5AEACF"];
      default:
        return ["#014576"];
    }
  };

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <BarChart
        series={[{ data: seriesData, type: "bar" }]}
        xAxis={[
          {
            scaleType: "band",
            data: seriesLabel,
            colorMap: {
              type: "piecewise",
              thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
              colors: filteredColor(type),
            },
          },
        ]}
        height={250}
        borderRadius={5}
        showTooltip
        showHighlight
      />
    </Stack>
  );
}
