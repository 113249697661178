import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { NumericFormat } from "react-number-format";

const AverageCardBased = (props) => {
  const { data, title, icon, isAverage } = props;

  return (
    <Paper
      sx={{
        p: 3,
        borderRadius: "10px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "80%" }}>
              {isAverage ? (
                <NumericFormat
                  value={data}
                  decimalScale={2}
                  displayType="text"
                  style={{
                    fontWeight: "600",
                    letterSpacing: "-1px",
                    fontSize: "24px",
                    lineHeight: "36px",
                  }}
                />
              ) : (
                <NumericFormat
                  value={data}
                  thousandSeparator=","
                  displayType="text"
                  style={{
                    fontWeight: "600",
                    letterSpacing: "-1px",
                    fontSize: "24px",
                    lineHeight: "36px",
                  }}
                />
              )}
              <br />
              <Typography variant="caption" color="#888888">
                {title}
              </Typography>
            </div>
            <div
              style={{
                width: "20%",
                textAlign: "right",
                alignContent: "center",
              }}
            >
              {icon}
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function SummaryAverage({ ...props }) {
  const { average, title, icon, isAverage } = props;

  return (
    <AverageCardBased
      data={average}
      title={title}
      icon={icon}
      isAverage={isAverage}
    />
  );
}
