import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function CustomizedSnackbars({ ...props }) {
  const { isOpen, isClose, status } = props;

  return (
    <div>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={isClose}>
        <Alert
          onClose={isClose}
          severity={status ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {status
            ? "Login Success"
            : "Looks like your entered wrong Email Address or Password"}
        </Alert>
      </Snackbar>
    </div>
  );
}
